
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';
    import {namespace} from 'vuex-class';
    import {getModule} from 'vuex-module-decorators';
    import PlaceInput from '@/components/forms/PlaceInput.vue';
    import GuestForm from '@/components/santevet/GuestForm.vue';
    import AnimalInsurance from '@/components/AnimalInsurance.vue';

    import {
      IClient,
      IAnimal,
      IBooking,
      IPlaceSuggestion,
      IUser,
      IAgenda,
      ISantevetGetQuotationParams,
      IVeterinarian,
      IImg,
    } from '@/types';

    import {
      AnimalModule,
    } from '@/store/modules';

    const accountNamespace = namespace('account');
    const bookingNamespace = namespace('booking');
    const animalNamespace = namespace('animal');

    @Component<LeadForm>({
      components: {
          PlaceInput,
          GuestForm,
          AnimalInsurance,
      },
    })
    export default class LeadForm extends Vue {
      public selectedAnimal: IAnimal | null = null;
      public zip: string = '';
      public city: IPlaceSuggestion|null = null;
      public zipUnvalidated: boolean = true;
      public formValid: boolean = false;
      public animalsLoaded: boolean = false;
      public step: number = 1;
      public adresse: string = '';
      public canInsure: boolean = false;
      public animalsCanInsure: IAnimal[] = [];
      public veterinarian: IVeterinarian|null = null;
      public checkbox: boolean|null = null;
      public loadingUrl: boolean = false;

      @accountNamespace.Getter('loggedUser')
      public loggedUser!: IUser;

      public images: IImg[] = [
        {
          src: require('@/assets/home/pubSlideshow1.jpg'),
        },
        {
          src: require('@/assets/home/pubSlideshow2.jpg'),
        },
        {
          src: require('@/assets/home/pubSlideshow3.jpg'),
        },
      ];

      @Prop({
            type: String,
            required: false,
        })
      public mode!: string;

      @Prop({
            type: Array,
            required: false,
        })
      public utms!: Record<string, []>;

      @accountNamespace.Getter('loggedClient')
      public client!: IClient;

      @animalNamespace.Getter('animalsList')
      public animals!: IAnimal[];

      @bookingNamespace.Getter('latestCreatedBooking')
      public processBooking!: IBooking;

      get agenda(): IAgenda | null {
          if (!(this.veterinarian && this.veterinarian.agendas)) {
              return null;
          }

          const agenda =  this.veterinarian.agendas.find((val: IAgenda) => {
              return val.id === this.$route.query.agenda_id;
          });

          return agenda ? agenda : null;
      }

      public getZipcode () {
        this.city?.raw_data?.address_components.find((component: any) =>{
          if(component.types.includes("postal_code")) this.zip = component.long_name;
        });
      }

      public zipRules = [
        (v: string) => !!v || 'Ce champs est requis',
        (v: string) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v) || 'doit etre un code postal',
      ];

      public cityRules = [
        (v: string) => !!v || 'Ce champs est requis',
      ];

      public validateCheckbox = [
        (v: boolean) => v || 'Vous devez accepter les conditions',
      ];

      public toggleAnimal(animal: IAnimal) {
        this.selectedAnimal = this.selectedAnimal === animal ? null : animal;
      }

      public handleSubmit() {
        const params: ISantevetGetQuotationParams = {};
        params.clientId = this.client.id;
        params.animalId = this.selectedAnimal?.id;
        params.cellPhone = this.client.cell_phone;

        if (this.processBooking) {
          params.city = this.veterinarian?.agenda?.organization?.address?.city;
          params.zip = this.veterinarian?.agenda?.organization?.address?.postal_code;
        } else {
            params.city = this.city?.title.split(',');
            params.city = params.city[0];
            params.zip = this.zip;
        }

        this.loadingUrl = true;

        getModule(AnimalModule, this.$store)
          .santevetGetQuotation(params)
            .then((data) => {
              const url = data.url;
              window.location.href = url;
            },
          )
        ;
      }

      public canInsureAnimal(animalId: string) {
        return new Promise<IAnimal[]>((resolve, reject) => {
          getModule(AnimalModule, this.$store)
            .santevetInsuranceRates({clientId: this.client.id as string, animalId})
              .then( (data) => {
                if (!data.rates) {
                  return;
                }

                const findAnimal = this.animals.find((animal: IAnimal) => animalId === animal.id) as IAnimal;
                this.animalsCanInsure.push(findAnimal);
                resolve(this.animalsCanInsure);
              },
            );
          },
        );
      }

      public veterinarianInfos() {
        if (!this.$route.query.agenda_id) {
          return;
        }
        this.$api.agenda.getById(this.$route.query.agenda_id as string)
          .then((veterinarian: IVeterinarian) => {
              this.veterinarian = veterinarian;
              const agenda =  veterinarian.agendas?.find((val: IAgenda) => {
                  return val.id === this.$route.query.agenda_id;
              });
              if (this.veterinarian) this.veterinarian.agenda = agenda;
          });
      }

      public nextStep() {
        this.step = 1;
      }

      public fetchAnimals() {
        getModule(AnimalModule, this.$store)
          .fetchAnimals(this.client.id)
            .then(() => {
              this.animals.map((animal, i, animals) => {
                  if (!animal.birth_date) {
                    return;
                  }

                  this.canInsureAnimal(animal.id).then((e) => {
                      this.animalsLoaded = true;
                      if (e.length === 1) {
                        const findAnimal = this.animals.find(
                          (anml: IAnimal) => this.animalsCanInsure[0].id === anml.id) as IAnimal;
                        this.selectedAnimal = findAnimal;
                      }
                    },
                  );
                },
              );
              this.animalsLoaded = true;
            },
          )
        ;

      }

      public mounted() {
        if (this.client) {
          this.fetchAnimals();
        }

        this.veterinarianInfos();
      }
    }
